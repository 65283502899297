/*
 * @Description: 设备接口
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-05-08 15:35:51
 * @LastEditors: 支磊
 * @LastEditTime: 2022-02-20 20:44:58
 */
import base from '@/config'; // 导入接口域名列表
import axios from '@/utils/cmshttps'; // 导入http中创建的axios实例
import axios2 from '@/utils/https'; // 导入http中创建的axios实例
import qs from 'qs'

function headerF() {
  let header = {}
  if (localStorage.getItem("SiteId") && localStorage.getItem("SiteId") != -1) {
    header = {
      'SiteId': localStorage.getItem("SiteId")
    }
  }

  return header
}
export function tree(data) { //设备分组树列表
  return axios2.post(`${base.baseApi}/honeywell/getHoneyDeviceGroup`, qs.stringify(data), {});
}
export function treeDevice(data) { //设备分组树列表
  return axios2.post(`${base.baseApi}/honeywell/getHoneyWellDevice`, qs.stringify(data), {});
}
export function list() { //设备列表
  let header = headerF()
  return axios.get(`${base.cmsBaseApi}/device/v2/list`, {
    headers: {
      ...header
    }
  });
}
export function deptlist() { //设备分组列表
  let header = headerF()
  return axios.get(`${base.cmsBaseApi}/dept/v2/list`, {
    headers: {
      ...header
    }
  });
}



export function createDevice(data) { //创建设备
  let header = headerF()
  return axios.post(`${base.cmsBaseApi}/device/v2/create`, data, {
    headers: {
      ...header
    }
  });
}
export function detail(data) { //设备信息
  let header = headerF()
  return axios.get(`${base.cmsBaseApi}/device/v2/detail?id=${data}`, {
    headers: {
      ...header
    }
  });
}
export function getRelationHoneyDevice(data) { // 获取关联设备  https://e.gitee.com/jufa/docs/299981/file/752504?sub_id=5112113
  return axios2.post(`${base.baseApi}/honeywell/getRelationHoneyDevice`, qs.stringify(data));
}
export function getMyHoneyDevice(data) { // 获取自有设备  https://e.gitee.com/jufa/docs/299981/file/752504?sub_id=5112113
  return axios2.post(`${base.baseApi}/honeywell/getMyHoneyDevice`, qs.stringify(data));
}
export function addHoneyDevice(data) { // 绑定  https://e.gitee.com/jufa/docs/299981/file/752504?sub_id=5112113
  return axios2.post(`${base.baseApi}/honeywell/addHoneyDevice`, qs.stringify(data));
}
export function addHoneyDeviceGroup(data) { //添加分组 https://e.gitee.com/jufa/docs/299981/file/752504?sub_id=4955991
  return axios2.post(`${base.baseApi}/honeywell/addHoneyDeviceGroup`, qs.stringify(data));
}
export function deletegroup(data) { //删除分组 https://e.gitee.com/jufa/docs/299981/file/752504?sub_id=4955991
  return axios2.post(`${base.baseApi}/honeywell/deleteGroup`, qs.stringify(data));
}
export function update(data) { //编辑设备 https://e.gitee.com/jufa/docs/299981/file/752504?sub_id=4955991
  return axios2.post(`${base.baseApi}/honeywell/updateGroupName`, qs.stringify(data));
}
export function updateGroupToGroup(data) { //移动设备分组 https://e.gitee.com/jufa/docs/299981/file/752504?sub_id=4955991
  return axios2.post(`${base.baseApi}/honeywell/updateGroupToGroup`, qs.stringify(data));
}
export function deleteDevice(data) { // https://e.gitee.com/jufa/docs/299981/file/752504?sub_id=4955991
  return axios2.post(`${base.baseApi}/honeywell/deleteHoneyDevice`, qs.stringify(data));
}
export function updateHoneyDeviceGroup(data) { // 移动设备 https://e.gitee.com/jufa/docs/299981/file/752504?sub_id=4955991
  return axios2.post(`${base.baseApi}/honeywell/updateHoneyDeviceGroup`, qs.stringify(data));
}