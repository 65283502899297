/*
 * @Description: 设备接口
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-05-08 15:35:51
 * @LastEditors: 支磊
 * @LastEditTime: 2021-10-22 10:32:29
 */
import base from '@/config'; // 导入接口域名列表
import axios from '@/utils/cmshttps'; // 导入http中创建的axios实例
import qs from 'qs'

function headerF() {
  let header = {}
  if (localStorage.getItem("SiteId") && localStorage.getItem("SiteId") != -1) {
    header = {
      'SiteId': localStorage.getItem("SiteId")
    }
  }
  return header
}
export function detailOverview(data) { //设备信息
  let header = headerF()
  return axios.get(`${base.cmsBaseApi}/device/v2/detailOverview?id=${data}`, {
    headers: {
      ...header
    }
  });
}
export function uploadPic(data) { //上传设备图片
  let header = headerF()
  return axios.post(`${base.cmsBaseApi}/device/v2/uploadPic`, data, {
    headers: {
      ...header
    }
  });
}
export function update(data) { //更新设备信息
  let header = headerF()
  return axios.put(`${base.cmsBaseApi}/device/v2/update`, data, {
    headers: {
      ...header
    }
  });
}
export function uploadAnnex(data) { //上传设备附件
  let header = headerF()
  return axios.post(`${base.cmsBaseApi}/device/v2/uploadAnnex`, data, {
    headers: {
      ...header
    }
  });
}