<!--
 * @Description: 裁剪图片弹窗
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-06-17 09:45:10
 * @LastEditors: 支磊
 * @LastEditTime: 2021-06-17 15:25:03
-->
<template>
  <div class="CropperComponents">
    <div class="model"></div>
    <div class="cropper">
      <i class="close el-icon el-icon-close" @click="closeF()"></i>
      <div class="head">裁剪图片</div>
      <div class="main">
        <vueCropper
          ref="cropper"
          :img="option.img"
          :outputSize="option.size"
          :outputType="option.outputType"
          :info="true"
          :full="option.full"
          :canMove="option.canMove"
          :canMoveBox="option.canMoveBox"
          :original="option.original"
          :autoCrop="option.autoCrop"
          :fixed="option.fixed"
          :fixedNumber="option.fixedNumber"
          :centerBox="option.centerBox"
          :infoTrue="option.infoTrue"
          :fixedBox="option.fixedBox"
        ></vueCropper>
      </div>
      <div class="footer">
        <el-button @click="closeF()" size="small" class="cancelBtn" plain
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="finish">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CropperComponents",
  components: {},
  props: {
    imgUrl: {},
  },
  data() {
    return {
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: "jpeg", // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        // autoCropWidth: 300, // 默认生成截图框宽度
        // autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [4, 3], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: false, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
    };
  },

  computed: {},
  //监听执行
  watch: {},
  mounted() {
    this.$nextTick(function () {
      this.$set(this.option, "img", this.imgUrl);
    });
  },
  methods: {
    closeF() {
      this.$emit("close");
    },
    // 点击裁剪，这一步是可以拿到处理后的地址
    finish() {
      this.$refs.cropper.getCropBlob((data) => {
        this.$emit("imgUrl", data);
        this.$emit("close");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.CropperComponents {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  .model {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: #000;
  }
  .cropper {
    width: 860px;
    background: #fff;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    padding: 28px;

    .close {
      color: #909399;
      font-size: 24px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      z-index: 9;
      &:hover {
        color: $main_color;
      }
    }
    .head {
      @extend .title_green;
      margin-bottom: 6px;
    }
    .main {
      height: 500px;
    }
    .footer {
      padding-top: 20px;
      text-align: right;
    }
  }
}
</style>
